<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <i2-form-input
            v-model="model.name"
            placeholder="Username"
            name="username"
            label="Nome"
            rules="required"
          />
        </b-col>
        <b-col sm="6">
          <i2-form-input
            v-model="model.email"
            name="email"
            placeholder="email"
            label="Email"
            rules="required|email"
          />
        </b-col>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2"
            @click="updateData"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    I2FormInput,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      model: {
        name: '',
        email: '',
        uuid: '',
      },
    }
  },
  async mounted() {
    this.model = await this.me()
  },
  methods: {
    ...mapActions('auth', ['me', 'setUser']),
    async updateData() {
      const data = { ...this.model }
      const response = await this.$http.put(`users/${this.model.uuid}`, data)
      if (response.error) {
        if (response.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error_message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          return false
        }
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Perfil salvo com sucesso.',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      await this.setUser(response)
      localStorage.setItem('userData', JSON.stringify(response))
      return true
    },
  },
}
</script>
